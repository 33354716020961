import { MEAN } from "src/model/ranking/constants";
import { ratingConfidence } from "src/model/ranking/RatingConfidence";
import { transitTimes as d_score } from "src/model/ranking/TransitTimes";
import { PlaceAssemblage, TransitPair } from "src/model/types";
import { findBestMatch } from "string-similarity";
import { get } from "lodash";

const createPlaceAssemblages = (
  nearbySearchResults: google.maps.places.PlaceResult[],
  distanceMatrixResponse: google.maps.DistanceMatrixResponse
): PlaceAssemblage[] => {
  const placeAssemblage = nearbySearchResults.map(
    (nearbySearchResult: google.maps.places.PlaceResult): PlaceAssemblage => {
      const { vicinity = "" } = nearbySearchResult;

      // it's fucking street vs st...
      // solution: use document distance
      const { destinationAddresses, rows, originAddresses } =
        distanceMatrixResponse;

      const { bestMatchIndex: destinationIndex } = findBestMatch(
        vicinity,
        destinationAddresses
      );

      const transitTimes: TransitPair[] = rows.map(
        (
          row: google.maps.DistanceMatrixResponseRow,
          index: number
        ): TransitPair => {
          return {
            destination: destinationAddresses[destinationIndex], // this is mainly for sanity
            origin: originAddresses[index],
            times: row.elements[destinationIndex],
          };
        }
      );

      const r_z = ratingConfidence({
        rating: nearbySearchResult.rating ?? MEAN,
        numberOfRatings: nearbySearchResult.user_ratings_total ?? 1,
      });

      const d = d_score({
        transitTime_a: get(transitTimes[0], "times.duration.value"),
        transitTime_b: get(transitTimes[1], "times.duration.value"),
        rating: nearbySearchResult.rating ?? MEAN,
      });

      return {
        transitTimes,
        placeResult: nearbySearchResult,
        scores: {
          d,
          r_z,
        },
      };
    }
  );
  return placeAssemblage;
};

export { createPlaceAssemblages };
