import { IosShare, Share } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { get, sample } from "lodash";
import React from "react";

const PlaceInfo = (props: any) => {
  const {
    name,
    vicinity,
    transitTimes,
    priceLevel,
    reviewCount,
    rating,
    handleShare,
  } = props;

  const dollarSigns: string = [...Array(priceLevel).keys()]
    .map(() => "$")
    .join("");

  const yourTime = get(transitTimes[0], "times.duration.text");
  const theirTime = get(transitTimes[1], "times.duration.text");
  const transitStatement =
    yourTime && theirTime
      ? `${yourTime} from you, 
    ${theirTime} from them.`
      : "No transit times available.";

  const shareText = sample(["let's go", "send it"]);

  return (
    <Card>
      <CardHeader
        title={name}
        subheader={`${rating} stars (${reviewCount} ratings), ${dollarSigns}`}
        action={
          <Button
            variant="outlined"
            onClick={handleShare}
            style={{
              margin: "10px",
            }}
          >
            {shareText}
          </Button>
        }
      />
      <CardContent>
        <Typography variant="body1">{vicinity.split(",")[0]}</Typography>
        <Typography variant="body1">{transitStatement}</Typography>
        {/* add dollar signs, stars, one liner, photo */}
        {/* add share function to just send it off */}
      </CardContent>
    </Card>
  );
};

export { PlaceInfo };
