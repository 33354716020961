const MEAN = 3.5;
const SE = 0.7;
const DELTA = 1;
const MAX_HANGOUT_DURATION_SECONDS = 18 * 60 * 60;
const TAU = 1;
const MINIMUM_HANGOUT_DURATION_SECONDS = 60;
const BETA_d = 1;
const BETA_r = 1;
const BETA_t = 1;

export {
  MINIMUM_HANGOUT_DURATION_SECONDS,
  MEAN,
  SE,
  DELTA,
  MAX_HANGOUT_DURATION_SECONDS,
  TAU,
  BETA_d,
  BETA_t,
  BETA_r,
};
