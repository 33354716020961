import { MEAN, SE } from "./constants";

type RatingConfidenceArgs = {
  rating: number;
  numberOfRatings: number;
  mean?: number;
  standardDeviation?: number;
};

const ratingConfidence = (args: RatingConfidenceArgs): number => {
  const { rating, numberOfRatings, mean = MEAN, standardDeviation = SE } = args;

  const se = standardDeviation / Math.sqrt(numberOfRatings);

  return (rating - se - mean) / standardDeviation;
};

export { ratingConfidence };
export type { RatingConfidenceArgs };
