import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { PlaceAssemblage } from "src/model/types";
import { PlaceInfo } from "./PlaceInfo";

const ResultsDrawer = (props: any) => {
  const {
    open,
    toggleDrawer,
    placeAssemblages,
    handleSwitch,
    index,
    handleShare,
  } = props;

  const { width: windowWidth } = useWindowDimensions();

  return (
    <div>
      <Carousel
        {...{
          onChange: (now, previous) => {
            handleSwitch(now);
          },
          cycleNavigation: true,
          autoPlay: false,
          index,
          indicators: false,
          swipe: true,
          navButtonsAlwaysVisible: true,
          fullHeightHover: true,
          animation: "slide",
          duration: 200,
          PrevIcon: <ArrowBackIosNew />,
          NextIcon: <ArrowForwardIos />,
          navButtonsProps: {
            style: {
              borderRadius: "0",
              color: "gray",
              backgroundColor: "transparent",
              margin: "0",
            },
          },
        }}
      >
        {placeAssemblages.map((placeAssemblage: PlaceAssemblage) => (
          <div
            {...{
              key: placeAssemblage.placeResult.place_id,
              style: {
                width: windowWidth,
                margin: "0 auto",
              },
            }}
          >
            <PlaceInfo
              {...{
                name: placeAssemblage.placeResult.name,
                vicinity: placeAssemblage.placeResult.vicinity,
                transitTimes: placeAssemblage.transitTimes,
                priceLevel: placeAssemblage.placeResult.price_level,
                reviewCount: placeAssemblage.placeResult.user_ratings_total,
                rating: placeAssemblage.placeResult.rating,
                handleShare,
              }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export { ResultsDrawer };
