import { DELTA, MEAN, SE } from "./constants";
import { normalcdf } from "./normalcdf";

type TransitTimeArgs = {
  transitTime_a: number;
  transitTime_b: number;
  rating: number;
  mean?: number;
  standardDeviation?: number;
  delta?: number;
};

const transitTimes = (args: Partial<TransitTimeArgs>): number | null => {
  const {
    transitTime_a: T_a,
    transitTime_b: T_b,
    rating,
    mean = MEAN,
    standardDeviation = SE,
    delta = DELTA,
  } = args;

  if (!T_a || !T_b || !rating) {
    return null;
  }

  const probFindingWorseRestaurant = normalcdf(mean, standardDeviation, rating);

  const denominator = delta * Math.pow(T_a - T_b, 2);

  return probFindingWorseRestaurant / denominator;
};

export { transitTimes };
export type { TransitTimeArgs };
