import {
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete as MapsAutocomplete } from "@react-google-maps/api";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, { useEffect } from "react";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type ConsoleProps = any;

const Console = (props: ConsoleProps) => {
  const {
    yourPlace,
    handleYourPlaceChanged,
    theirPlace,
    handleTheirPlaceChanged,
    linkSharingMode,
    setLinkSharingMode,
    startExpanded = true,
  } = props;

  const [expanded, setExpanded] = useState<boolean>(startExpanded);
  const [subtext, setSubtext] = useState<string>("you guys");
  const [yourRawLocation, setYourRawLocation] = useState<string>("");
  const [theirRawLocation, setTheirRawLocation] = useState<string>("");
  const [yourAutocomplete, setYourAutocomplete] = useState<any>(undefined);
  const [theirAutocomplete, setTheirAutocomplete] = useState<any>(undefined);

  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (theirPlace && yourPlace) {
      setExpanded(false);
      setSubtext(`${yourPlace.name} and ${theirPlace.name}`);
    }
  }, [theirPlace, yourPlace]);

  return (
    <Card style={{ width: "350px" }}>
      <CardHeader
        title={<Typography variant="h5">hoot | doog</Typography>}
        subheader={
          <Typography variant="body2">find food between {subtext}</Typography>
        }
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded}>
        <CardContent>
          <FormGroup style={{ margin: "10px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={linkSharingMode}
                  onChange={() => setLinkSharingMode(!linkSharingMode)}
                />
              }
              label="Link Sharing"
            />
          </FormGroup>
          <MapsAutocomplete
            onPlaceChanged={() => {
              const place: google.maps.places.PlaceResult =
                yourAutocomplete.getPlace();
              handleYourPlaceChanged(place);
            }}
            onLoad={(autocomplete) => {
              setYourAutocomplete(autocomplete);
            }}
          >
            <TextField
              onChange={(e) => {
                handleYourPlaceChanged(undefined);
                setYourRawLocation(e.target.value);
              }}
              label="Your place"
              value={yourPlace?.formatted_address ?? yourRawLocation}
              fullWidth
            />
          </MapsAutocomplete>
          <div style={{ margin: "25px" }} />
          <MapsAutocomplete
            onPlaceChanged={() => {
              const place: google.maps.places.PlaceResult =
                theirAutocomplete.getPlace();
              handleTheirPlaceChanged(place);
            }}
            onLoad={(autocomplete) => {
              setTheirAutocomplete(autocomplete);
            }}
          >
            <TextField
              label="Their place"
              onChange={(e) => {
                handleTheirPlaceChanged(undefined);
                setTheirRawLocation(e.target.value);
              }}
              value={theirPlace?.formatted_address ?? theirRawLocation}
              fullWidth
            />
          </MapsAutocomplete>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export { Console };
